import { useEffect, useRef, useState } from "react";
import { useAppStore } from "../../stores/app";
import { Country } from "../../types/enums";
import { useOnboardingStore } from "../../stores/onboarding";
import { IOnboardingForm } from "./types";
import Welcome from "./steps/welcome";
import Residence from "./steps/residence";
import IncomeSources from "./steps/incomeSources";
import Occupations from "./steps/occupations";
import BusinessStart from "./steps/businessStart";
import BusinessSize from "./steps/businessSize";
import PhoneUsage from "./steps/phoneUsage";
import Workspace from "./steps/workspace";
import BusinessCommute from "./steps/businessCommute";
import BusinessTravel from "./steps/businessTravel";
import BusinessMeal from "./steps/businessMeal";
import SummaryOverview from "./steps/summaryOverview";
import SalesTax from "./steps/salesTax";
import COGS from "./steps/cogs";
import HomeUsage from "./steps/homeUsage";
import CreateAccount from "./steps/createAccount";
import ConnectBank from "./steps/connectBank";
import BusinessAccounts from "./steps/businessAccounts";
import OperatingSystem from "src/screens/Onboarding/steps/operatingSystem";
import BusinessStructure from "src/screens/Onboarding/steps/businessStructure";
import HomeOfficeSqFt from "src/screens/Onboarding/steps/homeOfficeSqFt";
import { useSlideUpAnimation } from "src/screens/Onboarding/useSlideUpAnimation";

interface Step {
  Component: any;
  key: string;
}

const baseMapper: Step[] = [
  { key: "WELCOME", Component: Welcome },
  { key: "OPERATING_SYSTEM", Component: OperatingSystem },
  { key: "CURRENT_RESIDENCE", Component: Residence },
  { key: "BUSINESS_STRUCTURE", Component: BusinessStructure },
  { key: "INCOME_SOURCES", Component: IncomeSources },
  { key: "PROFESSIONAL_ACTIVITY", Component: Occupations },
  { key: "BUSINESS_START", Component: BusinessStart },
  { key: "BUSINESS_SIZE", Component: BusinessSize },
  { key: "PHONE_USAGE", Component: PhoneUsage },
  { key: "WORKSPACE", Component: Workspace },
  { key: "BUSINESS_COMMUTE", Component: BusinessCommute },
  { key: "BUSINESS_TRAVEL", Component: BusinessTravel },
  { key: "BUSINESS_MEAL", Component: BusinessMeal },
  { key: "SUMMARY_OVERVIEW", Component: SummaryOverview },
  { key: "CREATE_ACCOUNT", Component: CreateAccount },
];

const authMapper: Step[] = [
  { key: "CONNECT_BANK", Component: ConnectBank },
  { key: "BANK_ACCOUNTS", Component: BusinessAccounts },
];

export const useOnboardingWizard = (
  values: IOnboardingForm,
  isAuthenticated: boolean
) => {
  const lastAuthStatus = useRef(false);
  const [forceSlideUpAnimation, setForceSlideUpAnimation] = useState(false);
  const country = useAppStore((state) => state.selectedCountryCode);
  const { currentStep, setCurrentStep } = useOnboardingStore((state) => ({
    currentStep: state.currentStep,
    setCurrentStep: state.setCurrentStep,
  }));
  const [mapper, setMapper] = useState(baseMapper);
  const totalSteps = mapper.length + 1;
  const { animated, slideDownAnimation, slideUpAnimation, springProps } =
    useSlideUpAnimation();

  useEffect(() => {
    if (isAuthenticated && !lastAuthStatus.current) {
      setMapper(authMapper);
      setCurrentStep(0);
      lastAuthStatus.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    slideUpAnimation();
  }, []);

  const businessCommuteIndex = mapper.findIndex(
    (step) => step.key === "BUSINESS_COMMUTE"
  );
  const cogsIndex = mapper.findIndex(
    (step) => step.key === "COST_OF_GOODS_SOLD"
  );
  const homeOfficeSqFtIndex = mapper.findIndex(
    (step) => step.key === "HOME_SQ_FT"
  );
  const homeUsageIndex = mapper.findIndex((step) => step.key === "HOME_USAGE");
  const salesTaxIndex = mapper.findIndex((step) => step.key === "SALES_TAX");
  const summaryOverViewIndex = mapper.findIndex(
    (step) => step.key === "SUMMARY_OVERVIEW"
  );

  if (!isAuthenticated) {
    if (country === Country.CA) {
      // Show/hide sales tax step
      if (salesTaxIndex === -1) {
        setMapper([
          ...mapper.slice(0, summaryOverViewIndex),
          { key: "SALES_TAX", Component: SalesTax },
          ...mapper.slice(summaryOverViewIndex),
        ]);
      }
    } else {
      if (salesTaxIndex !== -1) {
        setMapper([
          ...mapper.slice(0, salesTaxIndex),
          ...mapper.slice(salesTaxIndex + 1),
        ]);
      }
    }

    if (values?.occupations?.has("27767ada-53ab-48ab-aaa9-dd9dd0b03dd1")) {
      if (cogsIndex !== -1) {
        setMapper([
          ...mapper.slice(0, cogsIndex),
          ...mapper.slice(cogsIndex + 1),
        ]);
      }
    } else {
      if (cogsIndex === -1) {
        if (country === Country.CA && salesTaxIndex !== -1) {
          setMapper([
            ...mapper.slice(0, salesTaxIndex),
            { key: "COST_OF_GOODS_SOLD", Component: COGS },
            ...mapper.slice(salesTaxIndex),
          ]);
        } else {
          setMapper([
            ...mapper.slice(0, summaryOverViewIndex),
            { key: "COST_OF_GOODS_SOLD", Component: COGS },
            ...mapper.slice(summaryOverViewIndex),
          ]);
        }
      }
    }

    // Show/hide home usage step
    if (country === Country.CA) {
      if (values.workspace === "HOME_OFFICE") {
        if (homeUsageIndex === -1) {
          setMapper([
            ...mapper.slice(0, businessCommuteIndex),
            { key: "HOME_USAGE", Component: HomeUsage },
            ...mapper.slice(businessCommuteIndex),
          ]);
        }
      } else {
        if (homeUsageIndex !== -1) {
          setMapper([
            ...mapper.slice(0, homeUsageIndex),
            ...mapper.slice(homeUsageIndex + 1),
          ]);
        }
      }
    } else {
      if (values.workspace === "HOME_OFFICE") {
        if (homeOfficeSqFtIndex === -1) {
          setMapper([
            ...mapper.slice(0, businessCommuteIndex),
            { key: "HOME_SQ_FT", Component: HomeOfficeSqFt },
            ...mapper.slice(businessCommuteIndex),
          ]);
        }
      } else {
        if (homeOfficeSqFtIndex !== -1) {
          setMapper([
            ...mapper.slice(0, homeOfficeSqFtIndex),
            ...mapper.slice(homeOfficeSqFtIndex + 1),
          ]);
        }
      }
    }
  }

  const decrementStep = () => {
    slideDownAnimation();
    setCurrentStep(currentStep - 1);
  };

  const incrementStep = () => {
    slideUpAnimation();
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  return {
    animated,
    currentStep,
    decrementStep,
    forceSlideUpAnimation: () =>
      setForceSlideUpAnimation(!forceSlideUpAnimation),
    incrementStep,
    mapper,
    slideUpAnimation,
    springProps,
    totalSteps,
  };
};
