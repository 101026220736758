import React, { useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useAuth } from "../services/auth";
import { useQuery } from "react-query";
import {
  BENJI_API_KEYS,
  IFetchUserSettingsResponse,
  Profile,
} from "../services/types";
import {
  fetchBankConnections,
  fetchBusinesses,
  fetchUserProfile,
  fetchUserSettings,
} from "../services/benji";
import { ProfileSettingOnboardingStep, ProfileSettings } from "../types/enums";
import {
  transformBenjiBusiness,
  transformUserProfile,
} from "../services/transformers";
import { useNavigate } from "react-router";
import Spinner from "../components/Spinner";

const Auth = () => {
  const refetchCountProfile = useRef(0);
  const { isAuthenticated, isBearerSet } = useAuth();
  const navigate = useNavigate();

  const {
    data: profile,
    refetch: refetchProfile,
    isFetching,
  } = useQuery<any, any, Profile>(
    BENJI_API_KEYS.USER,
    () => fetchUserProfile(),
    {
      enabled: isAuthenticated && isBearerSet,
      onError: (err) => {
        console.error(err);
        if (refetchCountProfile.current < 5) {
          refetchCountProfile.current++;
        } else {
          refetchCountProfile.current = 7;
          navigate("/login");
          return;
        }
      },
      onSuccess: (data) => {
        if (!data && refetchCountProfile.current < 3) {
          refetchCountProfile.current++;
        } else if (!data && refetchCountProfile.current > 3) {
          refetchCountProfile.current = 7;
          navigate("/login");
          return;
        }
      },
      select: (data) => transformUserProfile(data?.data),
    }
  );
  useEffect(() => {
    if (!isFetching && !profile?.email && refetchCountProfile.current < 3) {
      refetchProfile();
    }
  }, [profile, isFetching]);
  const { data: profileSettings } = useQuery<IFetchUserSettingsResponse>(
    BENJI_API_KEYS.USER_SETTINGS,
    () => fetchUserSettings(profile?.userId || ""),
    {
      enabled: isAuthenticated && isBearerSet && !!profile?.userId,
      onSuccess: (data) => {
        const setting = data?.data?.find(
          (setting) => setting.key === ProfileSettings.ONBOARDING_STEP
        );
      },
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
    }
  );
  const { data: businesses } = useQuery(
    BENJI_API_KEYS.BUSINESSES,
    () => fetchBusinesses(),
    {
      enabled: isAuthenticated && isBearerSet,
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      select: (data) => data?.data?.map(transformBenjiBusiness),
    }
  );
  useQuery([BENJI_API_KEYS.BANK_CONNECTIONS], () => fetchBankConnections(), {
    enabled: !!businesses && !!profileSettings,
    onSuccess: (hasConnections) => {
      const onboardingSetting = profileSettings?.data?.find(
        (setting) => setting.key === ProfileSettings.ONBOARDING_STEP
      );
      if (
        hasConnections ||
        onboardingSetting?.value ===
          ProfileSettingOnboardingStep.ONBOARDING_STEP_COMPLETED
      ) {
        console.log("redirecting to success");
        navigate("/download");
        return;
      }
      if (
        onboardingSetting?.value ===
          ProfileSettingOnboardingStep.ONBOARDING_STEP_BANK_ACCOUNT ||
        onboardingSetting?.value ===
          ProfileSettingOnboardingStep.ONBOARDING_STEP_COMPLETED_SIGNUP
      ) {
        navigate("/onboarding/bank-connection");
        return;
      } else {
        console.log("other redirection");
        navigate("/signup");
        return;
      }
    },
    select: (data) => data?.data?.length > 0,
  });

  return <Spinner />;
};

export default Auth;
