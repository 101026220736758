import * as Sentry from "@sentry/react";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import Routes from "./routes";
import "./index.css";
import { useAppStore } from "src/stores/app";
import { useTranslation } from "react-i18next";
import { useAuth } from "./services/auth";
import { benjiAuthBaseDeprecated, benjiAuthBaseV1 } from "./services/base";

const useStyles = makeStyles((theme) => ({
  "@global": {
    a: {
      color: theme.palette.primary.main,
      "&:visited": theme.palette.primary.main,
    },
  },
  // styles included offset library conflicts
  root: {
    border: 0,
    display: "flex",
    padding: 0,
    position: "relative",
    top: 0,
  },
  content: {
    backgroundColor: "white",
    flexGrow: 1,
    height: "100vh",
    paddingTop: 0,
    paddingLeft: 0,
  },
}));

export const GetHistory = () => {
  return null;
};

const App = () => {
  const { i18n } = useTranslation();
  const { selectedCountryCode } = useAppStore((state) => ({
    selectedCountryCode: state.selectedCountryCode,
  }));
  const {
    isAuthenticated,
    isBearerSet,
    setIsBearerSet,
    setToken,
    token,
    webAuth,
  } = useAuth();
  useEffect(() => {
    i18n.changeLanguage(`en-${selectedCountryCode}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountryCode]);
  // useEffect(() => {
  //   const fetchCountry = async () => {
  //     await fetch("https://ip-api.com/json")
  //       .then((res) => res.json())
  //       .then(async (response) => {
  //         if (response?.countryCode === Country.CA) {
  //           setSelectedCountryCode(Country.CA);
  //           await i18n.changeLanguage(`en-${Country.CA}`);
  //         } else {
  //           setSelectedCountryCode(Country.US);
  //           await i18n.changeLanguage(`en-${Country.US}`);
  //         }
  //       })
  //       .catch(() => {
  //         console.log("Request failed");
  //       });
  //   };
  //   fetchCountry();
  // }, []);
  useEffect(() => {
    const setBearerToken = async () => {
      if (isAuthenticated && typeof window !== "undefined") {
        benjiAuthBaseV1.defaults.headers.Authorization = `Bearer ${token?.accessToken}`;
        benjiAuthBaseDeprecated.defaults.headers.Authorization = `Bearer ${token?.accessToken}`;
        setIsBearerSet(true);
      }
    };
    if (!isBearerSet) {
      setBearerToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, token]);

  useEffect(() => {
    console.log("window.location.hash", window.location.hash);
    if (!token) {
      webAuth.parseHash(
        { hash: window.location.hash },
        function (err, authResult) {
          if (err) {
            console.log(err);
            Sentry.captureException(err);
            return;
          }
          setToken(authResult);
        }
      );
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  const classes = useStyles();
  return (
    <main className={classes.content}>
      <Routes />
    </main>
  );
};
export default Sentry.withProfiler(App);
