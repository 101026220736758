import React, { FC, useEffect, useState } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import { Box, CircularProgress, FormGroup } from "@mui/material";
import {
  AMP_EVENTS,
  AMP_PROPERTIES,
  BENJI_API_KEYS,
  IRegion,
} from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import Option from "../../../components/Option";
import { IBasicStepProps } from "../types";
import ActionRedirect from "../components/ActionRedirect";
import Input from "../../../components/Input";
import useDebounce from "../../../hooks/useDebounce";
import { makeStyles } from "@mui/styles";
import defaultTheme from "../../../styles/theme";
import { useQuery } from "react-query";
import { fetchRegionsByCountryCode } from "../../../services/benji";
import { useAppStore } from "../../../stores/app";
import { Country, Region } from "../../../types/enums";
import { getRegionFlag } from "../../../styles/map";
import BannerEmailCollect from "../../../components/Banner/EmailCollect";
import Button, { ButtonVariant } from "../../../components/Button";
import { useTranslation } from "react-i18next";

const quebecRegionId = "db25d246-6195-4779-b222-6f54aae4d4a9";

const Residence: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const [query, setQuery] = useState("");
  const { i18n } = useTranslation();
  const debouncedQuery = useDebounce(query, 350);
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  const { selectedCountryCode, setSelectedCountryCode } = useAppStore(
    (state) => ({
      selectedCountryCode: state.selectedCountryCode,
      setSelectedCountryCode: state.setSelectedCountryCode,
    })
  );
  const { data: regions, isLoading } = useQuery(
    [BENJI_API_KEYS.REGIONS, selectedCountryCode],
    () => fetchRegionsByCountryCode(selectedCountryCode),
    {
      select: (data) => data?.data?.Regions as Array<IRegion>,
    }
  );
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingCurrentResidence);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = regions
    ?.filter(
      (region) =>
        query === "" ||
        region?.name?.toLowerCase()?.includes(debouncedQuery?.toLowerCase())
    )
    ?.map((region, index) => {
      const isActive = formik?.values?.region === region?.id;
      return (
        <Option
          key={`option_${region.id}`}
          label={region.name}
          onClick={() => {
            formik.setFieldValue("region", region.id);
            try {
              trackAmpEvent(AMP_EVENTS.onboardingCurrentResidence, {
                [AMP_PROPERTIES.option]: formik.values.region,
              });
            } catch (err) {
              console.error(err);
            }

            if (region.id !== quebecRegionId) {
              incrementStep();
            }
          }}
          startAdornment={
            <Box
              style={{ alignItems: "center", display: "flex", width: "36px" }}
            >
              {getRegionFlag(selectedCountryCode, region.region_code as Region)}
            </Box>
          }
          selected={isActive}
          value={region.region_code}
        />
      );
    });

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("CURRENT_RESIDENCE.SUBHEADING", { ns: "onboarding" })}
        subtitleStyle={classes.subtitleStyle}
        title={t("CURRENT_RESIDENCE.HEADING", { ns: "onboarding" })}
      >
        <FormGroup>
          {!isLoading ? (
            <>
              <Button
                className={classes.button}
                label={t("SWITCH_TO", {
                  ns: "common",
                  value:
                    selectedCountryCode === Country.US
                      ? "Canada"
                      : "United States",
                })}
                name="country-switch"
                variant={ButtonVariant.textNoUnderline}
                onClick={async () => {
                  const code =
                    selectedCountryCode === Country.US
                      ? Country.CA
                      : Country.US;
                  setSelectedCountryCode(code);
                  await i18n.changeLanguage(`en-${code}`);
                }}
              />
              {selectedCountryCode === Country.US ? (
                <Input
                  className={classes.searchField}
                  name="search"
                  label="Search"
                  onChange={(e) => {
                    setQuery(e.target.value || "");
                  }}
                  onClear={() => setQuery("")}
                  value={query}
                />
              ) : null}
              {options}
              <Box style={{ height: "16px" }} />
              {selectedCountryCode === Country.CA &&
              formik?.values?.region === quebecRegionId ? (
                <BannerEmailCollect
                  ctaText={t("CURRENT_RESIDENCE.HINT_CTA", {
                    ns: "onboarding",
                  })}
                  placeholder={t("CURRENT_RESIDENCE.HINT_PLACEHOLDER", {
                    ns: "onboarding",
                  })}
                  subtitle={t("CURRENT_RESIDENCE.HINT_SUBTITLE", {
                    ns: "onboarding",
                  })}
                  tagType="quebec"
                  title={t("CURRENT_RESIDENCE.HINT_TITLE", {
                    ns: "onboarding",
                  })}
                />
              ) : null}
              <ActionRedirect goToPreviousStep={decrementStep} />
            </>
          ) : (
            <Box className={classes.spinnerContainer}>
              <CircularProgress />
            </Box>
          )}
        </FormGroup>
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: "auto",
    marginBottom: "24px",
    padding: 0,
  },
  closeIcon: {
    marginRight: "0",
  },
  hint: {
    marginBottom: "24px",
  },
  label: {
    color: defaultTheme.colours.ink400,
  },
  searchField: {
    marginBottom: "24px",
    marginTop: 0,
    padding: 0,

    "& .MuiInputBase-root": {
      paddingRight: "6px",
    },
  },
  selectionItem: {
    marginBottom: "8px",
  },
  subtitleStyle: {
    marginBottom: "12px",
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "30% 0",
  },
}));

export default Residence;
