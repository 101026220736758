import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Illustration } from "../../assets/illustrations/success.svg";
import { Box } from "@mui/material";
import IllustrationLayout from "src/screens/Onboarding/steps/illustrationLayout";
import { makeStyles } from "@mui/styles";
import defaultTheme from "src/styles/theme";
import { useTranslation } from "react-i18next";

const CheckoutSuccess = () => {
  const classes = useStyles({});
  let [searchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("use effect");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Box className={classes.container}>
      <IllustrationLayout
        imageAlt="illustration"
        imageHeightDesktop="246px"
        imageHeightMobile="186px"
        illustration={
          <Illustration style={{ height: "inherit", width: "inherit" }} />
        }
        subtitle={t("CHECKOUT.SUCCESS.SUBTITLE", { ns: "plans" }) || ""}
        title={t("CHECKOUT.SUCCESS.TITLE", { ns: "plans" })}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingTop: defaultTheme.spacing[8],
    padding: "0 1rem",
    textAlign: "center",
    width: "100%",
  },
}));

export default CheckoutSuccess;
