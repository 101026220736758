import React, { FC, useEffect, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS, BENJI_API_KEYS, Profile } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import * as Sentry from "@sentry/react";
import PlaidLink from "../../../components/PlaidLink";
import Banner, { BannerVariant } from "../../../components/Banner";
import Heading, { HeadingVariant } from "../../../components/Heading";
import defaultTheme from "../../../styles/theme";
import IllustrationLayout from "./illustrationLayout";
import { ReactComponent as Illustration } from "../../../assets/illustrations/payment_processing.svg";
import { IBasicStepProps } from "../types";
import { useQuery } from "react-query";
import { fetchUserProfile } from "../../../services/benji";
import { transformUserProfile } from "../../../services/transformers";
import { Country } from "../../../types/enums";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { useOnboardingStore } from "../../../stores/onboarding";
import { Box } from "@mui/material";
import Text, { TextVariant } from "../../../components/Text";
import testimonialImage from "../../../assets/rachel-cheney-benji-testimonial.jpeg";
import useBreakpoint from "src/hooks/useBreakpoint";

const ConnectBank: FC<IBasicStepProps> = ({
  animated,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const [disableCTA, setDisableCTA] = useState(false);
  const refetchCountProfile = useRef(0);
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  const { setAccountMap, setLinkSessionId } = useOnboardingStore((state) => ({
    setAccountMap: state.setAccountMap,
    setLinkSessionId: state.setLinkSessionId,
  }));

  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingConnectBank);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [profileCountry, setProfileCountry] = useState("");
  const { isMobile } = useBreakpoint();

  const {
    data: profile,
    refetch: refetchProfile,
    isFetching,
  } = useQuery<any, any, Profile>(
    BENJI_API_KEYS.USER,
    () => fetchUserProfile(),
    {
      onError: (err) => {
        console.error(err);
        setProfileCountry(Country.US);
      },
      onSuccess: (data) => {
        if (!data && refetchCountProfile.current < 3) {
          refetchCountProfile.current++;
        } else {
          refetchCountProfile.current = 5;
          setProfileCountry(data?.countryCode as Country);
        }
      },
      select: (data) => transformUserProfile(data?.data),
    }
  );
  useEffect(() => {
    if (!isFetching && !profile?.email && refetchCountProfile.current < 3) {
      refetchProfile();
    }
  }, [profile, isFetching]);
  const handleConnectionSuccess = async (
    public_token: string,
    metadata: PlaidLinkOnSuccessMetadata
  ) => {
    setDisableCTA(true);
    setLinkSessionId(metadata.link_session_id);
    setAccountMap([
      {
        accounts: metadata.accounts,
        institution: metadata.institution,
        publicToken: public_token,
      },
    ]);
    try {
      trackAmpEvent(AMP_EVENTS.onboardingConnectBank);
    } catch (err) {
      console.error(err);
    }
    incrementStep();
  };

  return (
    <>
      <Box className={classes.testimonialContainer}>
        <Box className={classes.testimonal}>
          <Box className={classes.testimonalQuote}>
            <Heading variant={HeadingVariant.small}>
              {t("CONNECT_BANK.TESTIMONIAL.HEADING", { ns: "onboarding" })}
            </Heading>
          </Box>
          <Box style={{ height: "24px" }} />
          <Box className={classes.testimonalImage} />
          <Box style={{ height: "12px" }} />
          <Box className={classes.testimonalText}>
            <Text
              color={defaultTheme.colours.ink500}
              isBold
              variant={TextVariant.medium}
            >
              {t("CONNECT_BANK.TESTIMONIAL.NAME", { ns: "onboarding" })}
            </Text>
            <Text
              color={defaultTheme.colours.ink200}
              variant={TextVariant.small}
            >
              {t("CONNECT_BANK.TESTIMONIAL.OCCUPATION", { ns: "onboarding" })}
            </Text>
          </Box>
        </Box>
      </Box>
      <animated.div style={springProps}>
        <Box style={{ height: isMobile ? "417px" : "378px" }} />
        <IllustrationLayout
          imageAlt="illustration"
          imageHeightDesktop="297px"
          imageHeightMobile="225px"
          illustration={
            <Illustration style={{ height: "inherit", width: "inherit" }} />
          }
          subtitle={t("CONNECT_BANK.SUBHEADING", { ns: "onboarding" })}
          title={t("CONNECT_BANK.HEADING", { ns: "onboarding" })}
        >
          <Banner
            className={classes.hint}
            title={t("CONNECT_BANK.HINT_TITLE", { ns: "onboarding" })}
            subtitle={t("CONNECT_BANK.HINT_BODY", { ns: "onboarding" })}
            type={BannerVariant.highlight}
          />
          <PlaidLink
            className={classes.fullWidth}
            country={profileCountry as Country}
            isDisabled={disableCTA}
            handleSuccess={handleConnectionSuccess}
            label={t("CONNECT_BANK.PRIMARY_CTA", { ns: "onboarding" })}
          />
        </IllustrationLayout>
      </animated.div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
  hint: {
    marginBottom: "24px",
  },
  link: {
    color: defaultTheme.colours.night200,
    "&:hover": {
      color: defaultTheme.colours.night300,
    },
  },
  spinner: {
    alignItems: "center",
    display: "flex",
    height: "450px",
    justifyContent: "center",
    width: "100%",
  },
  testimonal: {
    flex: "none",
    order: "0",
    flexGrow: 0,

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${defaultTheme.spacing[8]} ${defaultTheme.spacing[6]}`,

    maxWidth: "480px",
    minHeight: "378px",
  },
  testimonialContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    position: "absolute",
    width: "100%",
    minHeight: "378px",
    left: "0px",
    top: "0px",

    background: defaultTheme.colours.day050,
  },
  testimonalImage: {
    background: `url(${testimonialImage})`,
    backgroundSize: "contain",
    borderRadius: "12px",
    flex: "none",
    flexGrow: 0,
    height: "64px",
    order: 0,
    width: "64px",
  },
  testimonalQuote: {
    color: defaultTheme.colours.night400,
    textAlign: "center",
  },
  testimonalText: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
}));

export default ConnectBank;
