import { Country } from "../types/enums";
import create from "zustand";
import { persist } from "zustand/middleware";

export interface ICountry {
  id: string;
  code: string;
  name: string;
}

export interface AppStore {
  selectedCountryCode: Country;
  selectedTaxYear: number;
  reset: () => void;
  setSelectedCountryCode: (selectedCountryCode: Country) => void;
  setSelectedTaxYear: (year: number) => void;
}

const initStore = {
  selectedCountryCode: Country.US,
  selectedTaxYear: new Date().getFullYear(),
};

export const useAppStore = create<any>(
  persist(
    (set, get) => ({
      ...initStore,
      reset: () => set(() => ({ ...initStore })),
      setSelectedCountryCode: (selectedCountryCode: Country) =>
        set(() => ({ selectedCountryCode })),
      setSelectedTaxYear: (selectedTaxYear: number) =>
        set(() => ({ selectedTaxYear })),
    }),
    {
      name: "benji-app-store",
    }
  )
);
