import React, { FC, ReactNode, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import Text, { TextVariant } from "../Text";
import Input from "../Input";
import Button, { ButtonVariant } from "../Button";
import * as Sentry from "@sentry/react";

import defaultTheme from "../../styles/theme";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { postCaptureEmail } from "../../services/benji";
import { AMP_EVENTS, IPostCaptureEmailArgs } from "../../services/types";
import { useAnalytics } from "../../services/analytics";
import { FormikProps, useFormik } from "formik";
import { useAppStore } from "src/stores/app";
import { Country } from "src/types/enums";

interface IUseStyles {
  hoverBackgroundColor: string;
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonBorder: string;
  borderColor: string;
  color: string;
}

enum BannerVariant {
  success = "success",
  warning = "warning",
}

const getButtonBackgroundColor = (variant: BannerVariant) => {
  switch (variant) {
    case BannerVariant.success:
      return defaultTheme.colours.successText;
    case BannerVariant.warning:
      return defaultTheme.colours.warningText;
    default:
      return defaultTheme.colours.highlightText;
  }
};

const getSuccessMessage = (tagType: string) => {
  switch (tagType) {
    case "android":
      return "EMAIL_CAPTURE.ANDROID_SUCCESS";
    case "incorporated":
      return "EMAIL_CAPTURE.INCORPORATED_SUCCESS";
    case "quebec":
      return "EMAIL_CAPTURE.QUEBEC_SUCCESS";
    default:
      return "";
  }
};

const BannerEmailCollect: FC<IBanner> = ({
  className,
  ctaText,
  formik,
  placeholder,
  subtitle,
  tagType,
  title,
}) => {
  const { t } = useTranslation();
  const [variant, setVariant] = useState<BannerVariant>(BannerVariant.warning);
  const classes = useStyles({
    backgroundColor: paletteBanner.backgroundColor[variant],
    hoverBackgroundColor: paletteBanner.hoverBackgroundColor[variant],
    borderColor: paletteBanner.borderColor[variant],
    buttonBackgroundColor: getButtonBackgroundColor(variant),
    buttonBorder: paletteBanner.buttonBorder[variant],
    color: paletteBanner.color[variant],
  });
  const [, setIsSubmissionError] = useState<boolean>(false);
  const { trackAmpEvent } = useAnalytics();
  const { selectedCountryCode } = useAppStore((state) => ({
    selectedCountryCode: state.selectedCountryCode,
  }));

  const emailForm = useFormik<{
    email: string;
    firstName?: string;
    lastName?: string;
  }>({
    enableReinitialize: true,
    initialValues: {
      email:
        decodeURIComponent(formik?.values?.email) === "undefined"
          ? ""
          : decodeURIComponent(formik?.values?.email) || "",
      firstName: formik?.values?.fullname?.split(" ")?.[0] || undefined,
      lastName: formik?.values?.fullname?.split(" ")?.[1] || undefined,
    },
    onSubmit: async (values) => {
      setIsSubmissionError(false);
      await captureEmail.mutateAsync({
        country: countryName,
        email: values?.email || "",
        tagType,
      });
    },
    validate: (values) => {
      const errors: {
        email?: string;
        firstName?: string;
        lastName?: string;
      } = {};

      if (values.email) {
        if (
          !values.email
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          errors.email =
            t("LOGIN_SCREEN.ERROR_EMAIL", {
              ns: "auth",
            }) || undefined;
        }
      }

      return errors;
    },
  });

  const captureEmail = useMutation(
    (args: IPostCaptureEmailArgs) => postCaptureEmail(args),
    {
      onError: (err) => {
        Sentry.captureException(err);
        setIsSubmissionError(true);
        return;
      },
      onSuccess: async (res) => {
        console.log("submit res", res);
        if (res?.status_code === 200) {
          if (!res?.err_code) {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingCaptureEmail, {
                email: emailForm?.values?.email,
              });
            } catch (err) {
              console.error(err);
            }
            setVariant(BannerVariant.success);
            return;
          }
        }
        setIsSubmissionError(true);
        return;
      },
    }
  );

  let countryName =
    selectedCountryCode === Country.CA ? "Canada" : "United States";
  if (!!formik?.values?.region) {
    countryName =
      selectedCountryCode === Country.CA ? "Canada" : "United States";
  }

  return (
    <Box className={`${classes.container} ${className}`}>
      {variant === BannerVariant.warning ? (
        <>
          <Box>
            {title && (
              <Text
                className={classes.title}
                isBold
                variant={TextVariant.small}
              >
                {title}
              </Text>
            )}
            <Text className={classes.subtitle} variant={TextVariant.small}>
              {subtitle}
            </Text>
            <Box style={{ height: "12px" }} />
          </Box>
          <Input
            autoComplete="email"
            className={classes.input}
            id="email"
            name="email"
            value={emailForm?.values?.email}
            onChange={(e) => {
              emailForm.setFieldValue("email", e.target.value.toLowerCase());
            }}
            isError={!!(emailForm.errors?.email && emailForm.errors?.email)}
            errorText={emailForm.errors?.email}
            placeholder={placeholder}
            type="email"
            tabIndex={2}
          />
          <Button
            className={classes.button}
            disabled={!!emailForm.errors?.email}
            isLoading={captureEmail.isLoading}
            label={ctaText}
            name="emailCollect"
            variant={ButtonVariant.contained}
            onClick={emailForm.handleSubmit}
          />
        </>
      ) : (
        <>
          <Text className={classes.subtitle} variant={TextVariant.small}>
            {t(getSuccessMessage(tagType), { ns: "onboarding" })}
          </Text>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "24px",
    borderRadius: "12px",
    maxWidth: "100%",
    color: ({ color }: IUseStyles) => color,
    backgroundColor: ({ backgroundColor }: IUseStyles) => backgroundColor,
  },
  input: {
    marginBottom: "24px",
    marginTop: 0,
  },
  nameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subtitle: {},
  title: {
    marginBottom: "4px",
  },
  button: {
    fontSize: "16px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    backgroundColor: ({ buttonBackgroundColor }: IUseStyles) =>
      buttonBackgroundColor,
    color: ({ color }: IUseStyles) => color,
    borderColor: ({ buttonBorder }: IUseStyles) => buttonBorder,
    "&:hover": {
      backgroundColor: ({ buttonBackgroundColor }: IUseStyles) =>
        buttonBackgroundColor,
      borderColor: ({ buttonBorder }: IUseStyles) => buttonBorder,
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes fadeOut": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
  animatedIn: {
    animation: `$fadeIn 300ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedOut: {
    animation: `$fadeOut 300ms ${theme.transitions.easing.easeInOut}`,
  },
}));

interface IBanner {
  className?: string;
  country?: string;
  ctaText: string;
  formik?: FormikProps<any>;
  placeholder?: string;
  subtitle: ReactNode;
  tagType: string;
  title?: string;
  variant?: BannerVariant;
}

const paletteBanner = {
  color: {
    [BannerVariant.success]: defaultTheme.colours.successText,
    [BannerVariant.warning]: defaultTheme.colours.warningText,
  },
  borderColor: {
    [BannerVariant.success]: defaultTheme.colours.successBorder,
    [BannerVariant.warning]: defaultTheme.colours.warningBorder,
  },
  buttonBorder: {
    [BannerVariant.success]: defaultTheme.colours.successIcon,
    [BannerVariant.warning]: defaultTheme.colours.warningIcon,
  },
  hoverBackgroundColor: {
    [BannerVariant.success]: defaultTheme.colours.successHover,
    [BannerVariant.warning]: defaultTheme.colours.warningHover,
  },
  backgroundColor: {
    [BannerVariant.success]: defaultTheme.colours.successSurface,
    [BannerVariant.warning]: defaultTheme.colours.warningSurface,
  },
};

export default BannerEmailCollect;
