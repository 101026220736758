import { BankAccount, Business, Connection, Profile } from "./types";

export const transformBankAccounts = (raw: any) => {
  const account: BankAccount = {
    bankInstitutionId: raw?.bank_institution_id,
    id: raw?.id,
    isBusiness: raw?.is_business,
    mask: raw?.mask,
    name: raw?.name,
    plaidAccountId: raw?.plaid_account_id,
    plaidAccountIdStr: raw?.plaid_account_id_str,
  };

  return account;
};

export const transformBankConnections = (raw: any) => {
  const connections: Connection = {
    accounts: raw?.Accounts?.map(transformBankAccounts),
    institution: {
      accessToken: raw?.Institution?.access_token,
      businessId: raw?.Institution?.business_id,
      id: raw?.Institution?.id,
      plaidInstitutionId: raw?.Institution?.plaid_institution_id,
      plaidItemId: raw?.Institution?.plaid_item_id,
    },
  };

  return connections;
};

export const transformBenjiBusiness = (raw: any) => {
  const business: Business = {
    id: raw?.id,
    countryId: raw?.country_id,
    ownerId: raw?.owner_id,
    type: {
      key: raw?.type?.key,
      i18n: raw?.type?.i18n,
    },
  };

  return business;
};

export const transformUserProfile = (raw: any) => {
  const profile: Profile = {
    countryCode: raw?.country_code,
    countryId: raw?.country_id,
    countryName: raw?.country_name,
    cronTimezone: raw?.cron_timezone,
    email: raw?.email,
    expectedOutcomes: raw?.expectedOutcomes?.split("↵"),
    firstName: raw?.first_name,
    ianaTimezone: raw?.iana_timezone,
    isConfirmed: raw?.is_confirmed,
    language: raw?.language,
    lastLogin: raw?.last_login,
    lastName: raw?.last_name,
    profileId: raw?.profile_id,
    userId: raw?.user_id,
  };
  return profile;
};
