import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  usePlaidLink,
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
} from "react-plaid-link";
import Button, { ButtonVariant } from "../Button";
import { Country } from "../../types/enums";
import { useMutation, useQuery } from "react-query";
import { IPostPlaidLinkEvent, PLAID_API_KEYS } from "../../services/types";
import { fetchPlaidLinkToken, postPlaidLinkEvent } from "../../services/benji";

interface IPlaidLink {
  className?: string;
  country?: Country;
  handleExit?: (...args: any) => void;
  handleSuccess?: (...args: any) => void;
  isDisabled?: boolean;
  isOutlineButton?: boolean;
  label?: string;
  linkId?: string;
  token?: string;
}

const PlaidLink = ({
  className,
  country,
  handleExit,
  handleSuccess = () => null,
  isDisabled = false,
  isOutlineButton = false,
  label,
  linkId = "",
  token,
}: IPlaidLink) => {
  const [, setIsLoading] = useState(false);
  const [savedToken, setSavedToken] = useState(token);
  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");

  const postLinkEvent = useMutation(
    (args: IPostPlaidLinkEvent) => postPlaidLinkEvent(args),
    {
      onError: (err) => {
        Sentry.captureException(err);
      },
      onSuccess: () => {},
    }
  );
  const { data: linkToken } = useQuery(
    `${PLAID_API_KEYS.CREATE_LINK_TOKEN}_${linkId}`,
    () => fetchPlaidLinkToken(country, token),
    {
      enabled: !!country && !token && !savedToken,
      onSuccess: (res) => {
        setIsLoading(false);
        setSavedToken(res);
      },
      select: (data) => data?.data?.link_token,
    }
  );
  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const config: PlaidLinkOptions = {
    // oauthStateId: `${process.env.REACT_APP_BASE_URL}/oauth-page.html`,
    clientName: "Benji",
    linkCustomizationName: "default",
    onSuccess: async (
      public_token: string,
      metadata: PlaidLinkOnSuccessMetadata
    ) => {
      // regular link flow
      try {
        await postLinkEvent.mutateAsync({
          error_code: "",
          error_type: "",
          link_session_id: metadata?.link_session_id,
          request_id: "",
          status: "",
          type: "success",
        });
        setIsLoading(false);
        handleSuccess && handleSuccess(public_token, metadata);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    onExit: async (
      error: null | PlaidLinkError,
      metadata: PlaidLinkOnExitMetadata
    ) => {
      await postLinkEvent.mutateAsync({
        error_code: error?.error_code as string,
        error_type: error?.error_type as string,
        link_session_id: metadata.link_session_id,
        request_id: metadata.request_id,
        status: metadata?.status || "",
        type: "exit",
      });
      setIsLoading(false);
      handleExit && handleExit(metadata);
    },
    onEvent: async (
      eventName: PlaidLinkStableEvent | string,
      metadata: PlaidLinkOnEventMetadata
    ) => {
      if (eventName === "ERROR" && metadata.error_code != null) {
        await postLinkEvent.mutateAsync({
          error_code: metadata?.error_code as string,
          error_type: metadata?.error_type as string,
          link_session_id: metadata.link_session_id,
          request_id: metadata.request_id,
          status: metadata?.exit_status || "",
          type: eventName,
        });
      }
    },
    token: linkToken || savedToken,

    //required for OAuth; if not using OAuth, set to null or omit:
    receivedRedirectUri: isOAuthRedirect ? window.location.href : undefined,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  return isOAuthRedirect ? (
    <></>
  ) : (
    <Button
      className={className}
      data-form-id="link-form-id-benji"
      disabled={isDisabled || (!linkToken && !token) || !ready}
      isLoading={!ready}
      label={label}
      name="plaid-link-btn"
      onClick={() => open()}
      variant={
        isOutlineButton ? ButtonVariant.outlined : ButtonVariant.contained
      }
    />
  );
};

export default PlaidLink;
