import React, { useEffect } from "react";
import Spinner from "../../components/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isPlanType } from "src/utils";
import { useMutation } from "react-query";
import * as Sentry from "@sentry/react";
import { fetchCheckoutInit } from "../../services/benji";

const CheckoutInit = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const generateCheckoutLink = useMutation(
    (args: any) => fetchCheckoutInit(args),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      onSuccess: (data) => {
        console.log("DATA", data);
        if (data?.status_code === 200) {
          window.location.href = data?.data;
        }
      },
    }
  );

  useEffect(() => {
    console.log("use effect");
    const userId = searchParams.get("userId");
    const planType = searchParams.get("planType");
    const taxYear = searchParams.get("taxYear");

    const getLink = async () => {
      await generateCheckoutLink.mutateAsync({
        selection: planType,
        tax_year: Number(taxYear),
        user_id: userId,
      });
    };

    if (!isPlanType(planType || "") || !Number(taxYear) || !userId) {
      console.log(
        "invalid checkout init params",
        userId,
        planType,
        Number(taxYear)
      );
      navigate("/checkout/error");
      return;
    }

    getLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return <Spinner />;
};

export default CheckoutInit;
