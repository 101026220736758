import React, { FC, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../stores/app";
import { useOnboardingStore } from "../../stores/onboarding";
import { useOnboardingWizard } from "./wizard";
import { useFormik } from "formik";
import { IOnboardingForm } from "./types";
import {
  AMP_EVENTS,
  BENJI_API_KEYS,
  IPostSignUpArgs,
} from "../../services/types";
import {
  LOWER_UPPER_PATTERN,
  NUMBER_PATTERN,
  SPECIAL_PATTERN,
} from "../../utils/password";
import { useMutation, useQuery } from "react-query";
import { fetchCountryList, postSignUp } from "../../services/benji";
import * as Sentry from "@sentry/react";
import { ErrorCodes } from "../../types/errors";
import { useAuth } from "../../services/auth";
import { useAnalytics } from "../../services/analytics";
import { Country } from "src/types/enums";
import OnboardingLayout from "../../layouts/OnboardingLayout";
import ProgressBar from "src/screens/Onboarding/components/ProgressBar";
import defaultTheme from "src/styles/theme";
import Button, { ButtonVariant } from "../../components/Button";
import { useNavigate } from "react-router";
import Text, { TextVariant } from "../../components/Text";

const Onboarding: FC<IOnboarding> = () => {
  const isRewardfulInitialized = window?.Rewardful?._initialized;
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isAuthenticated, login } = useAuth();
  const [errorCode, setErrorCode] = useState("");
  const { trackAmpEvent } = useAnalytics();
  const { selectedCountryCode, setSelectedCountryCode } = useAppStore(
    (state) => ({
      selectedCountryCode: state.selectedCountryCode,
      setSelectedCountryCode: state.setSelectedCountryCode,
    })
  );
  const { isLoading } = useOnboardingStore((state) => ({
    isLoading: state.isLoading,
  }));
  const { data: countries } = useQuery(
    BENJI_API_KEYS.COUNTRIES,
    () => fetchCountryList(),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      select: (data) =>
        data?.data?.Countries?.map((country: any) => ({
          id: country?.id,
          code: country?.country_code,
          name: country?.name,
        })),
    }
  );
  const createUser = useMutation(
    (newUser: IPostSignUpArgs) => postSignUp(newUser),
    {
      onError: (err) => {
        Sentry.captureException(err);
        setErrorCode(ErrorCodes.AUTH_101);
        return;
      },
      onSuccess: async (res) => {
        console.log("submit res", res);
        if (res?.status_code === 200) {
          if (!res?.err_code) {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingCreateAccount);
            } catch (err) {
              console.error(err);
            }
            // set Fullstory values
            if (window) {
              (window as any)?.FS?.setUserVars({
                displayName: formik.values.fullname,
                email: formik.values.email,
              });
            }
            await login(formik.values.email, formik.values.password);
            return;
          }

          switch (res?.err_code) {
            case ErrorCodes.AUTH_104:
              setErrorCode(ErrorCodes.AUTH_104);
              return;
            case ErrorCodes.TAXP_102:
              setErrorCode(ErrorCodes.AUTH_102);
              return;
            default:
              setErrorCode(ErrorCodes.AUTH_101);
              return;
          }
        } else {
          setErrorCode(ErrorCodes.AUTH_101);
          return;
        }
      },
    }
  );
  const formik = useFormik<IOnboardingForm>({
    enableReinitialize: true,
    initialValues: {
      businessCommute: new Set<string>([]),
      businessMeal: undefined,
      businessSize: new Set<string>(["MYSELF"]),
      businessStart: "",
      businessStructure: "",
      businessTravel: undefined,
      cogs: undefined,
      email: "",
      fullname: "",
      homeOfficeSqFt: undefined,
      homeUsage: 20,
      incomeSources: new Set<string>(["SELF_EMPLOYMENT"]),
      password: "",
      phoneUsage: 30,
      occupations: new Set<string>([]),
      region: "",
      referralCode: undefined,
      salesTax: "",
      signupCode: undefined,
      workspace: "",
    },
    onSubmit: async (values) => {
      const args: IPostSignUpArgs = {
        business_commute:
          values.businessCommute?.size > 0
            ? Array.from(values.businessCommute)
            : ["NONE"],
        business_meal: values.businessMeal || false,
        business_size: Array.from(values.businessSize),
        business_start: values.businessStart,
        business_travel: values.businessTravel || false,
        cogs: values.cogs || false,
        country_id:
          countries?.find((item: any) => item.code === selectedCountryCode)
            ?.id || "",
        email: values.email,
        full_name: values.fullname?.trim(),
        home_office_sq_ft: values.homeOfficeSqFt
          ? `${values.homeOfficeSqFt}`
          : "0",
        home_usage: values.homeUsage,
        income_sources: Array.from(values.incomeSources),
        password: values.password,
        phone_usage: values.phoneUsage,
        occupations: Array.from(values.occupations),
        region_id: values.region,
        referral_code: values.referralCode,
        sales_tax: values.salesTax || "NO_ACCOUNT",
        signup_code: values.signupCode,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        web_signup: true,
        workspace: values.workspace,
      };

      try {
        await createUser.mutateAsync(args);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    validate: (values) => {
      const errors: {
        email?: string;
        fullname?: string;
        password?: boolean;
      } = {};

      if (values.email) {
        if (
          !values.email
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          errors.email =
            t("LOGIN_SCREEN.ERROR_EMAIL", {
              ns: "auth",
            }) || undefined;
        }
      }

      if (values.fullname) {
        const nameSplit = values?.fullname?.split(" ");
        if (nameSplit.length < 2) {
          errors.fullname =
            t("CREATE_ACCOUNT.ERROR_FULLNAME_HINT", {
              ns: "onboarding",
            }) || undefined;
        }
      }

      if (values.password) {
        const isInvalid =
          !LOWER_UPPER_PATTERN.test(values.password) ||
          !NUMBER_PATTERN.test(values.password) ||
          !SPECIAL_PATTERN.test(values.password) ||
          values?.password?.length < 12;
        if (isInvalid) {
          errors.password = true;
        }
      }
      return errors;
    },
  });
  const {
    animated,
    currentStep,
    decrementStep,
    forceSlideUpAnimation,
    incrementStep,
    mapper,
    slideUpAnimation,
    springProps,
    totalSteps,
  } = useOnboardingWizard(formik.values, isAuthenticated);

  const RenderComponent = mapper[currentStep]?.Component;

  useEffect(() => {
    // get query params
    let params = new URLSearchParams(document.location.search);
    const partner = params.get("partner");
    const referral = params.get("referral");
    const country = params.get("country");
    const email = params.get("email");
    const firstName = params.get("firstName");
    const lastName = params.get("lastName");

    if (!!partner) {
      formik.setFieldValue("signupCode", partner);
      if (window) {
        window.history.pushState("", "", window.location.pathname);
      }
      console.log("promo code", formik.values.signupCode, partner);
    }
    if (!!referral) {
      formik.setFieldValue("referralCode", referral);
    }
    if (!!country) {
      setSelectedCountryCode(
        country === Country.CA ? "Canada" : "United States"
      );
    }
    if (!!email) {
      formik.setFieldValue("email", email);
    }
    if (!!firstName && !!lastName) {
      formik.setFieldValue("fullname", `${firstName} ${lastName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const partner = window?.Rewardful?.referral;
    if (!!partner) {
      formik.setFieldValue("signupCode", `rewardful-${partner}`);
      console.log("promo code reward", formik.values.signupCode, partner);
    }
  }, [formik, isRewardfulInitialized]);

  const showProgressBar = currentStep > 0 && !isAuthenticated;

  return (
    <Box className={classes.container}>
      {showProgressBar ? (
        <Box className={classes.progressContainer}>
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        </Box>
      ) : null}
      <OnboardingLayout>
        {isLoading ? (
          <Box className={classes.spinnerContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <RenderComponent
            animated={animated}
            forceSlideUpAnimation={forceSlideUpAnimation}
            formik={formik}
            decrementStep={decrementStep}
            incrementStep={incrementStep}
            errorCode={errorCode}
            setErrorCode={setErrorCode}
            slideUpAnimation={slideUpAnimation}
            springProps={springProps}
            t={t}
          />
        )}
      </OnboardingLayout>
      {isAuthenticated ? (
        <Box className={`${classes.footerContainer}`}>
          <Box className={classes.leftInner}>
            <a
              className={classes.button}
              href="mailto:support@betterwithbenji.com?subject=Inquiry%20from%20web%20signup"
            >
              <Text isBold variant={TextVariant.medium}>
                Need help?
              </Text>
            </a>
          </Box>
          <Button
            label={t("LOG_OUT", { ns: "common" })}
            name="logout"
            onClick={() => {
              console.log("logout");
              navigate("/logout");
            }}
            variant={ButtonVariant.textNoUnderline}
          />
        </Box>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
    width: "100vw",
  },
  progressContainer: {
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    padding: "48px 24px 0 24px",
    width: "480px",
    zIndex: 10,

    [theme.breakpoints.down("lg")]: {
      padding: "24px 24px 48px 24px",
      width: "100%",
    },
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "40%",
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "64px",
    padding: "0 24px",
    width: "100%",
    boxShadow: `0px -1px 0px ${defaultTheme.colours.stone200}`,
    backgroundColor: defaultTheme.colours.baseWhite,
  },
  button: {
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "4px",
    color: defaultTheme.colours.ink200,
    cursor: "pointer",
    display: "flex",
    height: "40px",
    padding: "8px",
    textDecorationLine: "none",
    textTransform: "none",
    width: "fit-content",

    "&:hover": {
      backgroundColor: defaultTheme.colours.stone050,
      border: "none",
      color: defaultTheme.colours.ink300,
    },
  },
  leftInner: {
    alignItems: "center",
    display: "flex",
  },
}));

interface IOnboarding {}

export default Onboarding;
