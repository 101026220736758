import { benjiAuthBaseV1, benjiAuthBaseDeprecated } from "./base";
import * as Sentry from "@sentry/react";
import {
  IPostBankAccountArgs,
  IPostBankConnectionArgs,
  IPostCaptureEmailArgs,
  IPostPlaidLinkEvent,
  IPostSignUpArgs,
  IUpdateUserArgs,
} from "./types";
import { Country } from "../types";

export const fetchBankConnections = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get("/business/bank/connections");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchBenjiCategories = async (countryCode: string = "") => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/category/benji?country_code=${countryCode}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchBusinesses = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get("/businesses");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchCheckoutInit = async (args) => {
  try {
    const { data } = await benjiAuthBaseV1.post(`/payment/generate`, args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchCountryList = async () => {
  try {
    const { data } = await benjiAuthBaseDeprecated.get("/location/countries");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchMailchimpUser = async (email: string) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/analytics/mailchimp/check?email=${email}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchOccupationsList = async () => {
  try {
    const { data } = await benjiAuthBaseDeprecated.get("/occupations");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchRegionsByCountryCode = async (code = Country.US) => {
  try {
    const { data } = await benjiAuthBaseDeprecated.get(
      `/location/regions?country_code=${code}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchTransactions = async ({
  businessId,
  limit = 100,
  offset = 0,
  pending,
  raw,
  reviewed,
  taxYear,
}: {
  businessId: string;
  limit: number;
  offset: number;
  pending?: boolean;
  raw?: boolean;
  reviewed?: boolean;
  taxYear: number;
}) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `business/${businessId}/transactions?taxYear=${taxYear}&limit=${limit}&offset=${offset}&pending=${pending}&raw=${raw}&reviewed=${reviewed}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchUserProfile = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get(`/user`);

    return data;
  } catch (err) {
    Sentry.captureException(err);
    return err
  }
};

export const fetchUserSettings = async (id: string) => {
  try {
    const { data } = await benjiAuthBaseV1.get(`/user/${id}/settings`);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postBankAccount = async (args: IPostBankAccountArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post("/business/bank/account", args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postBankConnection = async (args: IPostBankConnectionArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      "/business/bank/connection",
      args
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postCaptureEmail = async ({
  country,
  email,
  firstName,
  lastName,
  tagType,
}: IPostCaptureEmailArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      "/analytics/capture/onboarding",
      {
        country,
        email,
        first_name: firstName,
        last_name: lastName,
        tag_type: tagType,
      }
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postCompleteOnboarding = async (id: string) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      `/user/${id}/onboarding/complete`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postSignUp = async (args: IPostSignUpArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post("/user", args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postUserSetting = async (
  id: string,
  key: string,
  value: string
) => {
  try {
    const { data } = await benjiAuthBaseV1.post(`/user/${id}/setting`, {
      key,
      value,
    });

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const putUpdateUser = async (id: string, args: IUpdateUserArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.put(`/user/${id}`, args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchPlaidLinkToken = async (
  countryCode: Country = Country.US,
  token: string = ""
) => {
  const countryCodes = [countryCode];
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/plaid/create-link-token?country_codes=${countryCodes}&token=${token}`
    );
    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchPlaidInstitution = async (
  countryCode: Country = Country.US,
  id: string
) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/plaid/institution/${id}?country_code=${countryCode}`
    );
    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postPlaidLinkEvent = async (args: IPostPlaidLinkEvent) => {
  try {
    const { data } = await benjiAuthBaseV1.post("/plaid/link-event", args);
    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};
